@mixin mobile {
  @media (max-width: 600px) {
    @content;
  }
}

@mixin sm {
  @media (max-width: 300px) {
    @content;
  }
}

body {
  background-color: #fff !important;
  font-family: "Poppins" !important;
  color: #000;
}
.css-1f4hnf {
  width: 95vw;
}
.rules {
  border: 1px solid #fff;
  border-radius: 23px;
  padding: 5px 10px;
  font-size: 12px;
  color: #fff;
}
.rules a {
  color: #fff;
  text-decoration: none;
}
.rules {
  color: #fff !important;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0.01875rem;
  border-radius: 0.375rem;
  height: 30px;
  border: none;
  padding: 0.3125rem 0.5rem;
  text-align: center;
  background: linear-gradient(96deg, #cd4295 5.01%, #8f05bd 92.79%);
}

.title-color {
  background: linear-gradient(
      278deg,
      #d442a6 -3.36%,
      rgba(235, 0, 255, 0.19) 117.81%
    ),
    #081227 !important;
}

.btn-color {
  margin: 8px;
  background: #1f2937;
  border-radius: 5px;
  display: -ms-flexbox;
  padding: 5px;
  color: white;
}

.border-color {
  border: 1px solid #a116b2;
  border-top: none;
  border-radius: 0 0 8px 8px;
}

.card-title-color {
  background: linear-gradient(96deg, #cd4295 5.01%, #8f05bd 92.79%);
  border-radius: 8px 8px 0 0;
}

.header-bgcolor {
  background-color: #0e191e !important;
}

.body-bgcolor {
  // background-color: #0E191E !important
  background-color: #fff !important;
  // background-image: url("./Assets/Cricket.jpg");
  // background-size: cover;
}
.footer-bgcolor {
  background-color: #0e191e !important;
}
.sticky-footer-bgcolor {
  // background-color: #311528 !important
  background: linear-gradient(96deg, #cd4295 5.01%, #8f05bd 92.79%);
}

.btn-bgcolor {
  background-color: #1f2937 !important;
}
.card-bgcolor {
  background-color: #0e191e !important;
  border-radius: 8px 8px 8px 8px;
}
.upcomming-event-body {
  display: flex;
  align-items: center;
  margin: 0px 15px 0 0;
  background: #fff;
  color: black;
  padding: 0 5px;
  border-radius: 3px;
}
.lvnow-body {
  display: flex;
  align-items: center;
  margin: 0px 10px 0 0;
  background: green;
  color: #fff;
  padding: 0 5px;
  border-radius: 3px;
}
.lvnow {
  background-color: #fff;
  border-radius: 50%;
  height: 10px;
  width: 10px;
  margin-right: 5px;
  animation: 1s ease 0s infinite normal none running blink;
}
.bet-card-text{
  color: #fff;
}

.marquee-title{
  margin: 10px;
    color: #FFF;
    background: #b025a8;
    padding: 10px;
    border-radius: 10px;
}

.match-runner{
  font-size: 10px;
    padding: 1px 8px;
    color: #FFF;
    border-radius: 6px;
    line-height: 2;
}
.cursor-type{
  cursor: pointer;
}
.csv-download button{
  background-color: #0e191e !important;
  color: #fff;
  padding: 5px 10px;
  margin-bottom: 5px;
}
@keyframes blink {
  0%,
  100% {
    opacity: 1; /* Fully visible */
  }
  50% {
    opacity: 0; /* Fully invisible */
  }
}

@media screen and (min-width: 300px) and (max-width: 576px) {
  .betInfo {
    margin-top: 5px;
    border: none;
    display: flex;
    row-gap: 20px;
    flex-direction: column-reverse;
  }
  .betInfo button {
    width: 100% !important;
  }
}
